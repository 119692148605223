.container {
    display: flex;
    margin-top: 1px;
    /*caret-color: transparent*/
}


.container__mobile {
    display: flex;
    height: 100vh;
}

.container__sidebar {
    width: 300px;
    min-width: 300px;
    margin-left: 0;
    height: calc(100vh - 110px);
    transition: margin-left 0.3s;
    position: relative;
    z-index: 10;
    background: var(--surface-ground);
    border-right: solid 1px var(--surface-border)!important;
    margin-top:73px;
}
.container__sidebar__mobile {
    width: 300px;
    min-width: 300px;
    margin-left: 0;
    height: 100%;
    transition: margin-left 0.3s;
    position: absolute;
    z-index: 10;
    background: var(--surface-ground);
    border-right: solid 1px var(--surface-border)!important;
    top: 0vh;
}

.container__main {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 113px);
    width:100%;
    background: var(--surface-ground)!important;
    overflow:hidden;
    margin-top: 73px;

}

.container__main__mobile {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 95vh;
    width:100%;
    background: var(--surface-ground);
    overflow:hidden;

}




.p-accordion-content {
    background: rgba(255, 255, 255, 0.60) !important;
}


.p-accordion .p-accordion-header .p-accordion-header-link {
    background: rgba(255, 255, 255, 0.60) !important;
}



.journey {
    background: rgba(255, 255, 255, 0.8);
}




.mapContainer {
    display: flex;
    height: 100vh;
    position: relative;
}


.mapboxgl-ctrl-icon {
    background-image: url("#../assets/triangle.svg")  !important;
   /* background: #7dd5f8 !important;*/
}

.mapboxgl-ctrl-zoom-in:before {
    content: "+";
    font-weight: bold;
    font-size: 14px;
}


.mapboxgl-ctrl-zoom-out:before {
    content: "-";
    font-weight: bold;
    font-size: 16px;
}

.mapboxgl-ctrl-traffic:before {
    content: "t";
    font-weight: bold;
    font-size: 14px;
}

.mapboxgl-ctrl-satellite:before {
    content: "s";
    font-weight: bold;
    font-size: 14px;
}


.marker {
    /*background: rgba(255, 255, 255, 0.4);*/
    width: 140px;
    height: 140px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 14px;
    backface-visibility: hidden;
}


.startMarker {
    /*background: rgba(255, 255, 255, 0.4);*/
    width: 140px;
    height: 60px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 14px;
    backface-visibility: hidden;
}





.sideBarButton {
    background-color: var(--surface-ground);
    border: var(--surface-border) solid 1px;
    border-bottom: none;
    padding: 10px 15px 10px 15px;
    cursor: pointer;
    color: var(--text-color);
    font-weight: normal;
    border-radius: 5px 5px 0 0;
    font-size: 16px;
    position: absolute;
    right: -65px;
    top: 200px;
    z-index: 0;
    backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: rotate(90deg);
}



.sideBarButtonVList {
    background-color: var(--surface-ground) !important;
    border: 2px solid var(--surface-border)!important;
    padding: 3px!important;
    cursor: pointer!important;
    color: var(--text-color)!important;
    font-weight: normal!important;
    border-radius: 25px!important;
    font-size: 12px!important;
    margin-left: 5px!important;
    margin-right: 5px!important;
    height: 23px!important;
    width: 23px!important;

}


/*.clearfix {
    clear: both;
    height: 0;
    background: #7dd5f8;
    line-height: 0;
}*/

@media screen and (min-width: 768px) {
    .custom-toast {
        position: fixed;
        bottom: 1%;
        right: 1%;
        width: 30%;
    }
    .mapboxgl-ctrl-geocoder {
        height: auto;
    }
}
@media screen and (max-width: 767px) {
    .custom-toast {
        position: fixed;
        bottom: 1%;
        right: 1%;
        width: 50%;
    }

    .mapboxgl-ctrl-geocoder {
        height: 0px;
    }
}

.p-dialog {
    border: solid grey;
    border-width: 0 0 0 3px;
    /*max-height: 100%!important;*/
    /*top:7.3%!important;*/
    /*margin-right: 0 !important;*/
    /*margin-top: 0!important;*/
    /*top: 77px!important;*/
}



.p-inputgroup > .p-component, .p-inputgroup > .p-inputwrapper > .p-inputtext, .p-inputgroup > .p-float-label > .p-component {
    font-size: 1.0rem!important;
    border: none!important;
}
.p-calendar .p-inputtext{
    font-weight: bold!important;
}

.p-datascroller .p-datascroller-content {
    padding: 0!important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: var(--main-accent-colour)!important;
}

/*.p-inputswitch-checked .p-inputswitch-slider {*/
/*    background: rgba(92, 132, 89, 0.5)!important;*/
/*}*/

/*.p-inputswitch-checked .p-inputswitch-slider:before {*/
/*    background: rgba(92, 132, 89)!important;*/
/*}*/


.notificationScroller > .p-datascroller-content .p-datascroller-list li:nth-child(2n+2){
    background: rgba(0,0,0, 0.11)
}


/* customer datascroller for grid content start*/
.scrollerTest > .p-datascroller-content {
    background: var(--surface-ground);
    overflow-x: hidden;
}


.scrollerTest > .p-datascroller-content > .p-datascroller-list {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 100px;
    /*height: 81vh !important;*/
}

@media screen and (max-width: 576px)  {
    .scrollerTest > .p-datascroller-content > .p-datascroller-list > li {
        width: 100%;
        flex: 0 0 auto;
    }

    .eventGridContainer {
        width: 100%;
        flex: 0 0 auto;
    }
}

@media screen and (min-width: 768px)  {
    .scrollerTest > .p-datascroller-content > .p-datascroller-list > li {
        width: 50%;
        flex: 0 0 auto;
    }
    .eventGridContainer {
        width: 50%;
        flex: 0 0 auto;
    }
}

@media screen and (min-width: 992px)  {
    .scrollerTest > .p-datascroller-content > .p-datascroller-list > li {
        width: 33%;
        flex: 0 0 auto;
    }
    .eventGridContainer {
        width: 33%;
        flex: 0 0 auto;
    }
}

@media screen and (min-width: 1200px)  {
    .scrollerTest > .p-datascroller-content > .p-datascroller-list > li {
        width: 25%;
        flex: 0 0 auto;
    }
    .eventGridContainer {
        width: 25%;
        flex: 0 0 auto;
    }
}
/* customer datascroller for grid content end*/

.fleetFilterButton {
    user-select: none;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
    overflow: hidden;
    position: relative;
    display: inline-flex;
    cursor: pointer;
    padding: 0.3rem;
    margin: 3px;
    background: rgb(219, 217, 217);
    color: black;
    flex: 1 1 0%;
    font-size: 1rem;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
    border-radius: 4px;
    border: 0 none;
}
.arrow-long-right {
    box-sizing: border-box;
    position: relative;
    display: block;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    box-shadow: inset 0 0 0 2px;
    width: 110px;
    height: 6px;
}
.arrow-long-right::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 6px;
    height: 6px;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    right: 0;
    bottom: -2px
}

/*.mapSlider {*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*    z-index: 500;*/
/*    width: 100%;*/
/*    height: 25px;*/
/*    transition: 0.5s;*/
/*    transition-property: height;*/
/*    background: transparent;*/
/*}*/

/*.mapSliderShow {*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*    z-index: 500;*/
/*    width: 100%;*/
/*    height: 160px;*/
/*    transition: 0.5s;*/
/*    transition-property: height;*/
/*    background: transparent;*/

/*}*/

.mapboxgl-ctrl-logo {
    display: none!important;
}

.mapboxgl-ctrl-attrib {
    display: none!important;
}

.vListFilterInput {
    padding-left: 23px!important;
}
.vListFilterInput::placeholder{
    font-weight:bold;
}



.bottomCenterToast {
    bottom: 50px;
}

.eventsFiltersTabs > ul{
    height: 50px!important;
    border-bottom: none!important;
}

.vListParent  .vListScroller .p-datascroller-header {
    padding: 0px!important;
}

.vListParent  .vListScroller .p-datascroller-content {
    padding-bottom: 50px!important;
    scroll-snap-type: y mandatory;
}
.vListParent  .vListScroller .p-datascroller-content > ul > li {
    scroll-snap-align: end;
}


@keyframes selectedItemGradientFade {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}


/*.budgetMessage {*/
/*    background: transparent!important;*/
/*    color: var(--text-color) !important;*/
/*    padding: 2px!important;*/
/*}*/


/*.budgetMessage > .p-inline-message-icon {*/
/*    color: var(--text-color) !important;*/
/*}*/

.react-player{
    position: absolute;
    top: 0;
    left: 0;
}


.gridCard{
    transform: scale(1);
    position: relative;

}

.gridCard:hover {
    /*z-index: 10.0;*/
    transform: scale(1.05);
    transition: all 250ms ease-out;
}
.eventModal > .p-dialog-content {
    overflow-y: hidden;
}

.mapboxgl-ctrl-geocoder--input::placeholder{
    color: black!important;
}
.p-calendar-w-btn:not(.p-disabled).p-inputwrapper-focus {
    box-shadow: none;
}
.p-dropdown:not(.p-disabled).p-focus{
    box-shadow: none;
}
.p-confirm-popup {
    z-index:10000000000;
}
.p-knob-text {
    fill: var(--text-color);
}

.p-button .p-button-icon-left {
    margin-right: 0.1rem!important;
}
.eventsDropdownPanel > div{
    max-height: 500px!important;
}


.loader {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
}
.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid var(--text-color);
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 2s linear infinite;
}
.loader::after {
    animation-delay: 1s;
}

@keyframes animloader {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}


.activeChips {
    border-radius: 4px !important;
    padding: 0px 5px 0px 5px !important;
    /*margin: 0px 5px 0px 5px !important;*/
    margin: 3px;
}
